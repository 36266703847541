<template>
	<div class="box-style">
		<headerNav title="合同管理" leftNav="返回" @leftClick="back"></headerNav>
		<div class="content-style">
			<pullRefresh ref="pullRefresh" @refresh="onRefresh">
				<!-- 加载中 -->
				<div style="height: calc(100vh - 55px);display: flex;align-items: center;justify-content: center;" v-if="isLoading">
					<van-loading size="24px" vertical>加载中，请稍后...</van-loading>
				</div>
				<div class="pullRefresh-style" v-else>
					<div class="items-style">
						<div class="item1-style" v-for="obj in objData" :key="obj.title">
							<div class="item1-title">{{ obj.title }}</div>
							<div class="item-content">
								<div v-if="obj.list.length">
									<div class="item-tits">
										<div class="item-tit">
											<div class="item-lab">{{ obj.title }}：</div>
											<div class="item-val">{{ obj.list[0].contractNo }}</div>
										</div>
										<div class="item-tit">
											<div class="item-lab">签订时间：</div>
											<div class="item-val">{{ obj.list[0].signTime }}</div>
										</div>
									</div>
									<div class="item-imgs">
										<div class="item-imgs1">
											<div class="item-img" v-for="(item, index) in obj.list" :key="index" @click="handleToDetail(item)">
												<img :src="item.fileAdd" alt="" style="width: 100%;height: 100%;">
											</div>
										</div>
									</div>
								</div>
								<div class="empty" v-else>
									<img src="../../../../assets/uf21.png" alt="" style="width: 200px;">
									<div class="empty-text">暂无合同</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</pullRefresh>
		</div>
	</div>
</template>

<script>
import api from '@/api/api.js'
export default {
	data() {
		return {
			objData: [
				{ title: '预定合同', list: [] },
				{ title: '保值合同', list: [] },
				{ title: '补齐协议', list: [] },
			],
			list0: [],
			list1: [],
			list2: [],
			isLoading: false,
			info: {},
		}
	},
	methods: {
		back() {
			this.$router.push({ name: "deepenClicntDetailName", })
		},
		handleToDetail(item) {
			sessionStorage.setItem('deepenUserInfoContractManageDetail', JSON.stringify(item));
			this.$router.push({ name: "deepenUserInfoContractManageDetail" })
		},
		//获取列表
		async getList() {
			let obj = {
				Q_isDel_EQ: 0,
				Q_orderId_EQ: this.info.orderId
			}
			let res = await api.getContractManageList(obj)
			if (res.code == 200) {
				this.total = res.data.length;
				if (res.data.length === 0) {
					this.objData[0].list = []
					this.objData[1].list = []
					this.objData[2].list = []
				} else {
					res.data.forEach(item => {
						if (item.typeCode === '0') {
							this.objData[0].list.push(item)
						} else if (item.typeCode === '1') {
							this.objData[1].list.push(item)
						} else if (item.typeCode === '2') {
							this.objData[2].list.push(item)
						}
					})
				}
				if (this.$refs.pullRefresh) {
					this.$refs.pullRefresh.refreshSuccess()
				}
			}
			this.isLoading = false
		},
		// 下拉刷新
		onRefresh() {
			this.getList()
			this.objData[0].list = []
			this.objData[1].list = []
			this.objData[2].list = []
		},
	},
	mounted() {
		this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
		this.isLoading = true
		this.getList()
	},
}
</script>

<style scoped lang="scss">
.box-style {

	.content-style {
		overflow: auto;
		text-align: left;
		height: calc(100vh - 50px);

		.items-style {
			overflow: hidden;
		}

		.item1-style {
			margin: 16px 0;
			background: #fff;
			border-radius: 4px;
			padding: 10px;

			.item1-title {
				position: relative;
				padding-left: 8px;
				padding-bottom: 10px;
				font-size: 16px;
				height: 24px;
				line-height: 24px;
				font-weight: bold;
				color: #333333;

				&::before {
					content: ' ';
					position: absolute;
					left: 0;
					top: 5px;
					width: 4px;
					height: 14px;
					background: #0E8BFF;
					border-radius: 2px;
				}

				&::after {
					content: ' ';
					position: absolute;
					left: -50%;
					right: -50%;
					bottom: 0;
					height: 1px;
					background: #F5F5F5;
				}


			}

			.item-content {
				font-size: 14px;

				.item-tits {
					padding: 10px 0;

					.item-tit {
						display: flex;

						&:nth-child(2) {
							margin-top: 6px;
						}

						.item-lab {
							color: #888;
						}

						.item-val {
							color: #333333;
							font-size: 15px;
							flex: 1;
							word-wrap: break-word;
							word-break: break-all;
						}
					}
				}

				.item-imgs {
					position: relative;
					width: 100%;
					height: 150px;
					overflow-x: scroll;
					scrollbar-width: none;

					&::-webkit-scrollbar {
						height: 0;
					}

					.item-imgs1 {
						top: 0;
						left: 0;
						position: absolute;
						display: flex;
						flex-wrap: nowrap;
					}

					.item-img {
						width: 300px;
						height: 140px;
						border-radius: 4px;
						overflow: hidden;

						&:not(:first-child) {
							margin-left: 10px;
						}
					}
				}


				.empty {
					padding: 14px 0 30px;
					display: flex;
					flex-direction: column;
					align-items: center;
					position: relative;

					.empty-text {
						font-size: 14px;
						color: #5E5E5E;
						margin-top: -14px;
					}
				}
			}
		}
	}
}
</style>
